import request from "@/api/request";

export function getFileMgtClass(query) {
  return request({
    url: "/api/fileMgt/class",
    method: "get",
    params: query,
  });
}

export function createFileMgtFile(data) {
  return request({
    url: "/api/fileMgt/file",
    method: "post",
    data: data,
  });
}

export function updateFileMgtFile(id, data) {
  return request({
    url: `/api/fileMgt/file/${id}`,
    method: "put",
    data: data,
  });
}

// 获取档案管理项目
export function getFileMgtProject(query) {
  return request({
    url: "/api/fileMgt/project",
    method: "get",
    params: query,
  });
}

// 获取档案管理项目 单个
export function getFileMgtProjectById(id) {
  return request({
    url: `/api/fileMgt/project/${id}`,
    method: "get",
    params: {},
  });
}

// 创建档案管理项目
export function createFileMgtProject(data) {
  return request({
    url: "/api/fileMgt/project",
    method: "post",
    data: data,
  });
}
// 更新档案管理项目
export function updateFileMgtProject(id, data) {
  return request({
    url: `/api/fileMgt/project/${id}`,
    method: "put",
    data: data,
  });
}
