import request from "@/api/request";
export default {
  data() {
    return {
      modalTitle: "新增",
      modalVisible: false,
      modalLoading: false,
      editId: null,
      dataSource: [],
      detail_columns: [],
      table_columns: [],
      form_config: {
        col: 24,
        formLayout: "vertical",
        textAlign: "left",
        columns: [],
      },
      showModalBtn: undefined,
      pagination: {
        total: 0,
        pageSize: 10,
        current: 1,
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "50", "100"],
      },
      params: {
        pageNum: 1,
        pageSize: 10,
      },
      listRes: {},
      record: null,
    };
  },
  methods: {
    getList() {
      let data = this.getListRequestHandle();
      let params = data.usePagination ? this.params : undefined;
      request({
        url: data.getApi,
        method: "get",
        params,
      }).then((res) => {
        this.dataSource = res.list;
        this.listRes = res;
        if (data.usePagination) {
          this.pagination.current = res.pageNum;
          this.pagination.pageSize = res.pageSize;
          this.pagination.total = res.total;
          this.params.pageNum = res.pageNum;
          this.params.pageSize = res.pageSize;
        }
        if (this.getListCB) {
          this.getListCB()
        }
      });
    },
    handleAdd() {
      this.editId = null;
      this.record = null;
      this.modalVisible = true;
      setTimeout(() => {
        this.$refs["modal-form"].resetFields();
        this.$refs["modal-form"].closeDisabledColumns("all", true);
        this.showModalBtn = undefined;
      });
    },
    handleEdit(record, disabled, type) {
      this.editId = record._id;
      this.record = record;
      this.modalVisible = true;

      if (this.beforeEdit) {
        record = this.beforeEdit(record, type);
      }
      setTimeout(() => {
        this.$refs["modal-form"].updateForm({ ...record }, true);
        if (disabled) {
          this.$refs["modal-form"].disabledColumns("all", true);
          this.showModalBtn = null;
        } else {
          this.$refs["modal-form"].closeDisabledColumns("all", true);
          this.showModalBtn = undefined;
        }
        if (this.afterEdit) {
          record = this.afterEdit(record, type);
        }
      });
    },
    handleDelete(recordId) {
      let params = this.deleteRequestHandle(recordId);
      let _this = this;
      this.$confirm({
        title: "确定删除该项目?",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          request({
            url: params.deleteApi,
            method: "delete",
          }).then((res) => {
            _this.$message.success("删除成功");
            _this.getList();
          });
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    },
    handleModalOk() {
      this.$refs["modal-form"].zformValidateFieldsAndScroll().then((res) => {
        this.modalVisible = true;
        let params = this.submitRequestHandle(res);
        if (!params) {
          return;
        }
        request({
          url: this.editId ? params.putApi : params.postApi,
          method: this.editId ? "put" : "post",
          data: { ...res, ...params.params },
        })
          .then((res) => {
            this.$message.success(this.editId ? "修改成功" : "添加成功");
            this.modalVisible = false;
            this.getList();
          })
          .finally((res) => {
            this.modalVisible = false;
          });
      });
    },
    handleModalCancel() {
      this.modalVisible = false;
    },
    tableAction() { },
    tableChangeHandle(pagination, filters, sorter) {
      console.log(pagination, filters, sorter);
      let {
        current = this.params.pageNum,
        pageSize = this.params.pageSize,
      } = pagination;
      this.params.pageNum = current;
      this.params.pageSize = pageSize;
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      if (this.tableChangeHandleCB) {
        this.tableChangeHandleCB()
      }
      this.getList();

    },
    searchHandle(values) {
      console.log(values);
      this.params = {
        ...this.params,
        queryValue: "",
      };
      for (const key in values) {
        if (values[key] !== undefined) {
          this.params[key] = values[key];
        }
      }
      this.getList();
    },
  },
};
