<template>
	<div class="dm-content">
		<a-row :gutter="12" style="height: 100%">
			<a-col :span="6" style="height: 100%;overflow: auto;">
				<a-directory-tree
					default-expand-all
					:treeData="treeData"
					:replaceFields="{
						title: 'name',
						children: 'children',
						key: '_id',
					}"
					@select="onSelect"
				>
				</a-directory-tree>
			</a-col>
			<a-col :span="18" style="height: 100%;overflow-y: auto;">
				<div>
					<zform
						:formConfig="searchFormConfig"
						:formData="{}"
						ref="duty-modal-form"
					></zform>
				</div>
				<div class="add-btn">
					<a-button type="primary" @click="uploadFile">
						创建项目
					</a-button>
				</div>
				<div>
					<ztable2
						:detailColumns="detailColumns"
						:columns="tableColumns"
						:dataSource="dataSource"
					>
						<span slot="status" slot-scope="text, record">
							<a-badge status="success" text="已归档" />
						</span>
						<span slot="action" slot-scope="text, record">
							<div class="editable-row-operations">
								<a
									href="javascript:void(0)"
									@click="handlePreview(record)"
									>查看</a
								>
								<a
									href="javascript:void(0)"
									@click="handleEditFile(record)"
									v-if="record.position !== 'DIRECTOR'"
									>修改</a
								>
							</div>
						</span>
					</ztable2>
				</div>
			</a-col>
		</a-row>

		<a-modal
			class="ywh-modal"
			title="创建项目"
			:visible="uploadFileModalVisible"
			:confirm-loading="uploadFileLoading"
			@ok="zmodalHandleOk"
			@cancel="zmodalHandleCancel"
			ok-text="提交"
			cancel-text="取消"
		>
			<zform
				:formConfig="uploadFileFormConfig"
				:formData="{}"
				ref="modal-form"
			></zform>
		</a-modal>
	</div>
</template>

<script>
import Smodal from '@/components/modal/index.vue'
import {
	getFileMgtClass,
	createFileMgtProject,
	updateFileMgtProject,
} from '@/api/fileMgt/fileMgt'
import tableMixin from '@/mixin/table.list.js'

export default {
	data() {
		return {
			editingId: null,
			uploadFileLoading: false,
			treeData: [],
			searchFormConfig: {
				btnsCol: 8,
				btns: [
					{
						name: '重置',
						type: 'reset',
						clickHandle: ({ form, btn }) => {
							this.params.name = null
							this.params.documentNumber = null
							this.getList()
						},
					},
					{
						name: '搜索',
						type: 'save',
						styleType: 'primary',
						clickHandle: ({ form, btn }) => {
							const val = form.getFieldsValue()
							this.params.name = val.name
							this.params.documentNumber = val.documentNumber
							this.getList()
						},
					},
				],
				col: 8,
				fixedLabelWidth: '80px',
				// formLayout: 'vertical',
				textAlign: 'right',
				columns: [
					{
						props: 'name',
						type: 'input',
						label: '项目名称:',
						placeholder: '请输入项目名称',
					},
				],
			},
			detailColumns: [
				{ type: 'time', props: 'createdAt', format: 'YYYY-MM-DD' },
			],
			tableColumns: [
				{
					dataIndex: 'name',
					title: '项目名称',
				},
				{
					dataIndex: 'fileCount',
					title: '已归档数量',
					width: 120,
				},
				{
					dataIndex: 'status',
					title: '状态',
					width: 120,
				},
				{
					dataIndex: 'createdAt',
					title: '上传时间',
					width: 130,
				},
				{
					dataIndex: 'createdBy',
					title: '上传人',
					width: 100,
				},
				{
					title: '操作',
					dataIndex: 'action',
					width: 140,
				},
			],
			params: {
				pageNum: 1,
				pageSize: 10,
				type: 2,
			},
			uploadFileModalVisible: false,
			uploadFileFormConfig: {
				col: 24,
				fixedLabelWidth: '80px',
				// formLayout: 'vertical',
				textAlign: 'right',
				columns: [
					{
						props: 'name',
						type: 'input',
						label: '项目名称:',
						placeholder: '请输入项目名称',
						rules: [
							{
								required: true,
							},
						],
					},
				],
			},
		}
	},
	mixins: [tableMixin],
	components: { Smodal },
	created() {
		getFileMgtClass({ type: 2 }).then((res) => {
			console.log(res)
			function formateTree(list) {
				for (const item of list) {
					if (!item.children || !item.children.length) {
						item.isLeaf = true
						continue
					}
					item.selectable = false
					formateTree(item.children)
				}
			}
			formateTree(res.list)
			this.treeData = res.list
		})
	},
	methods: {
		handlePreview(record) {
			const { _id } = record
			this.$router.push(`/fileManagement/dynamic/preview/${_id}`)
		},
		handleEditFile(record) {
			this.editingId = record._id
			this.uploadFileModalVisible = true
			this.$nextTick(() => {
				this.$refs['modal-form'].updateForm({
					name: record.name,
					filePath: record.filePath,
				})
			})
		},
		// 查询列表接口api处理
		getListRequestHandle() {
			return {
				// 查询接口
				getApi: `/api/fileMgt/project`,
				usePagination: true,
			}
		},
		onSelect(keys, event) {
			this.params.classId = keys[0]
			this.getList()
		},
		zmodalHandleOk() {
			this.uploadFileLoading = true
			this.$refs['modal-form']
				.zformValidateFieldsAndScroll()
				.then(async (values) => {
					try {
						if (this.editingId) {
							await updateFileMgtProject(this.editingId, {
								name: values.name,
								classId: this.params.classId,
							})
						} else {
							await createFileMgtProject({
								name: values.name,
								classId: this.params.classId,
							})
						}
					} catch (error) {
						this.$message.success('创建失败！')
						console.log(error)
						this.uploadFileLoading = false
						return
					}

					this.$message.success('创建成功！')
					this.uploadFileLoading = false
					this.getList()
					this.zmodalHandleCancel()
				})
				.catch((err) => {
					console.log(err)
					this.uploadFileLoading = false
				})
		},
		zmodalHandleCancel() {
			this.$refs['modal-form'].resetFields()
			this.uploadFileModalVisible = false
		},
		uploadFile() {
			this.editingId = null
			if (!this.params.classId) {
				this.$message.warning('请选择一个目录！')
				return
			}
			this.uploadFileModalVisible = true
			console.log('uploadFileModalVisible', this.uploadFileModalVisible)
		},
	},
}
</script>

<style lang="less" scoped>
.dm-content {
	padding: 8px;
	background: white;
	height: 100%;
	width: 100%;
	.add-btn {
		text-align: right;
		margin: 12px 0px;
	}
}
</style>